import React from 'react';
import * as PropTypes from 'prop-types';
import Text from '../../atoms/Text';

const SectionHeaderMainText = ({ children, ...props }) => (
  <Text lineHeight={'1.6'} weight={'light'} transform={'uppercase'} block size={'32px'} {...props}>
    {children}
  </Text>
);

SectionHeaderMainText.propTypes = {
  children: PropTypes.string.isRequired,
};

SectionHeaderMainText.defaultProps = {};

export default SectionHeaderMainText;
