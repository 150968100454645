import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as da from './da';
import * as de from './de';
import * as en from './en';
import * as es from './es';
import * as fr from './fr';
import * as it from './it';
import * as ja from './ja';
import * as nl from './nl';
import * as pt from './pt';
import * as ru from './ru';

export const fallbackLng = 'en';
//const localGlobal = typeof localStorage !== 'undefined' && localStorage && localStorage.getItem;
//export const defaultLanguage = localGlobal.getItem('i18nextLng') || fallbackLng;

const options = {
  // order and from where user language should be detected
  order: ['localStorage'],
  lookupLocalStorage: 'i18nextLng',
  // cache user language on
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources: {
      da: { ...da },
      de: { ...de },
      en: { ...en },
      es: { ...es },
      fr: { ...fr },
      it: { ...it },
      ja: { ...ja },
      nl: { ...nl },
      pt: { ...pt },
      ru: { ...ru },
    },
    fallbackLng,

    // have a country namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    returnObjects: true,

    react: {
      wait: true,
    },
  });
