import React from 'react';
import * as PropTypes from 'prop-types';
import { COLORS } from '../assets/styles/variables';
import FontIcon from './FontIcon';

const SocialIcon = ({ socialIcon, socialLink, size }) => (
  <a href={socialLink}>
    <FontIcon size={size ? size : '45px'} icon={socialIcon} color={COLORS.txtLight} />
  </a>
);

SocialIcon.propTypes = {
  socialIcon: PropTypes.string.isRequired,
  socialLink: PropTypes.string.isRequired,
};

SocialIcon.defaultProps = {};

export default SocialIcon;
