import * as PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { BtnTypes } from '../../assets/styles/Utils';
import { COLORS } from '../../assets/styles/variables';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import ForwardLinkComp from '../../molecules/ForwardLink';
import { phone, mobile } from '../../utils/media';

const CARD_SPACING = 80;

const ShowcaseCardWrapper = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  height: 390px;
  max-width: 1440px;
  padding: 0 3%;
  color: ${COLORS.txtDark};
  margin: 50px auto;
  align-items: center;

  ${phone(css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 20px auto;
  `)};
`;

const CardImage = styled.div`
  flex: 1;
  height: 100%;
  max-width: 620px;
  background-image: url(${({ image }) => require('../../assets/images/desktop/' + image)});
  background-size: cover;
  box-shadow: 0 2px 6px ${COLORS.shadowLight};
  background-position: ${({ bgPosition }) => (bgPosition ? bgPosition : 'left')};
  background-repeat: no-repeat;
  border: ${({ withBorderImage }) => (withBorderImage ? '1px solid ' + COLORS.border : '')};
  border-radius: 5px;
  margin: ${({ reverse }) => (reverse ? `0 0 0 ${CARD_SPACING}px` : `0 ${CARD_SPACING}px 0 0`)};

  ${mobile(css`
    margin: ${({ reverse }) => (reverse ? `0 0 0 ${CARD_SPACING / 3}px` : `0 ${CARD_SPACING / 3}px 0 0`)};
    height: 75%;
    background-image: url(${({ image }) => require('../../assets/images/tablet/' + image)});
  `)};

  ${phone(css`
    height: auto;
    min-height: 160px;
    max-width: 420px;
    width: 320px;
    background-size: cover;
    margin: auto;
    background-image: url(${({ image }) => require('../../assets/images/phone/' + image)});
  `)};
`;

const CardContentWrapper = styled.div`
  flex: 1;
  margin: 20px 0;

  ${phone(css`
    margin: 10px 0;
  `)}
`;

const CardTitleWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 2fr auto;
  font-size: 34px;
  padding: ${({ reverse }) => (reverse ? `0 0 0 ${CARD_SPACING}px` : `0 ${CARD_SPACING}px 0 0`)};

  @media only screen and (max-width: 1100px) {
    font-size: 28px;
  }

  ${phone(css`
    padding: 20px 10px;
  `)}
`;

const CardDescriptionWrapper = styled.div`
  margin-top: 15px;
  height: 170px;
  text-align: justify;
  overflow-y: auto;
  font-size: 17px;
  padding: ${({ reverse }) => (reverse ? `0 0 0 ${CARD_SPACING}px` : `0 ${CARD_SPACING}px 0 0`)};

  @media only screen and (max-width: 1100px) {
    font-size: 15px;
  }

  ${phone(css`
    padding: 10px;
    height: auto;
  `)}
`;

const CardButtonsWrapper = styled.div`
  padding: ${({ reverse }) => (reverse ? `0 0 0 ${CARD_SPACING}px` : `0 ${CARD_SPACING}px 0 0`)};
  margin-top: 40px;
  ${phone(css`
    padding: 10px;
  `)}
`;

const ForwardLink = styled(ForwardLinkComp)`
  ${phone(css`
    display: none;
  `)}
`;

const ForwardLinkMobile = styled(ForwardLinkComp)`
  display: none;
  ${phone(css`
    display: block;
    padding: 10px;
  `)}
`;

const ShowcaseCard = ({
  image,
  reverse,
  title,
  description,
  mainCb,
  secondaryCb,
  forwardLink,
  mainCbLabel,
  withBorderImage,
  secondaryCbLabel,
  forwardLinkLabel,
  bgPosition,
}) => {
  return (
    <ShowcaseCardWrapper reverse={reverse}>
      <CardImage withBorderImage={withBorderImage} reverse={reverse} image={image} bgPosition={bgPosition} />
      <CardContentWrapper>
        <CardTitleWrapper reverse={reverse}>
          <Text bold block lineHeight={1.2}>
            {title}
          </Text>
          {forwardLink && forwardLinkLabel && (
            <ForwardLink icon={'arrow-right'} iconColor={COLORS.brand} underlined linkTo={forwardLink}>
              {forwardLinkLabel}
            </ForwardLink>
          )}
        </CardTitleWrapper>
        <CardDescriptionWrapper reverse={reverse}>
          <Text lineHeight={'2'}>{description}</Text>
        </CardDescriptionWrapper>
        {forwardLink && forwardLinkLabel && (
          <ForwardLinkMobile icon={'arrow-right'} iconColor={COLORS.brand} underlined linkTo={forwardLink}>
            {forwardLinkLabel}
          </ForwardLinkMobile>
        )}
        <CardButtonsWrapper reverse={reverse}>
          {mainCbLabel && (
            <Button role={BtnTypes.primary} bgColor={COLORS.agricultureGreen} onClick={mainCb}>
              {mainCbLabel}
            </Button>
          )}
          {secondaryCbLabel && (
            <Button role={BtnTypes.outlinedSecondary} onClick={secondaryCb}>
              {secondaryCbLabel}
            </Button>
          )}
        </CardButtonsWrapper>
      </CardContentWrapper>
    </ShowcaseCardWrapper>
  );
};

ShowcaseCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  withBorderImage: PropTypes.bool,
  description: PropTypes.string,
  mainCbLabel: PropTypes.string,
  mainCb: PropTypes.func,
  secondaryCbLabel: PropTypes.string,
  secondaryCb: PropTypes.func,
  forwardLinkLabel: PropTypes.string,
  forwardLink: PropTypes.string,
  bgPosition: PropTypes.string,
};

ShowcaseCard.defaultProps = {};

export default ShowcaseCard;
