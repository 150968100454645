export const COLORS = {
  agricultureGreen: '#048643',
  primary: '#127EC3',
  brand: '#46bc01',
  brandHover: '#48c201',
  bgLight: '#FFFFFF',
  bgDark: '#333333',
  border: '#b7b7b7',
  txtDark: '#333333',
  txtLight: '#FFFFFF',
  shadowLight: '#a8a8a8',
  shadow: '#7a7a7a',
  shadowDark: '#383838',
  sectionBg: '#ebf0f4',
  link: '#007dc5',
  txtError: '#ff3b11',
};

export const SIZES = {
  full: '1920px',
  min: '1024px',
  mid: '1280px',
};
