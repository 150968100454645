import * as PropTypes from 'prop-types';
import React from 'react';
import { CenteredRowGrid } from '../../assets/styles/Utils';
import styled, { css } from 'styled-components';
import { COLORS } from '../../assets/styles/variables';
import { mobile, phone } from '../../utils/media';

const BannerContainer = styled(CenteredRowGrid)`
  position: relative;
  height: ${(props) => (props.height ? props.height : 640)}px;
  color: ${COLORS.txtLight};
  text-align: center;
  background-image: url(${({ bgImage }) => require('../../assets/images/desktop/' + bgImage)});
  background-size: cover;
  background-position: ${({ bgPosition }) => bgPosition};
  color: ${COLORS.txtLight};

  ${mobile(css`
    margin-top: 20px;
    min-height: 100vh;
    min-width: 100vw;
    height: ${(props) => (props.height ? props.height * 0.5 : 465)}px;
    background-position: 80% 0;
    background-image: url(${({ bgImage }) => require('../../assets/images/tablet/' + bgImage)});
  `)}

  ${phone(css`
    background-position: 50% 0;
    background-image: url(${({ bgImage }) => require('../../assets/images/phone/' + bgImage)});
  `)}
`;

const BannerContainerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 99;
`;

const BannerContent = styled.div`
  z-index: 100;
`;

const CTABanner = ({ className, height, bgImage, children, style, bgPosition }) => {
  return (
    <BannerContainer className={className} bgPosition={bgPosition} height={height} bgImage={bgImage} style={style}>
      <BannerContainerOverlay />
      <BannerContent>{children}</BannerContent>
    </BannerContainer>
  );
};

CTABanner.propTypes = {
  bgImage: PropTypes.string,
  bgPosition: PropTypes.string,
  height: PropTypes.number,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

CTABanner.defaultProps = {
  bgPosition: 'center',
};

export default CTABanner;
