import * as PropTypes from 'prop-types';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styled, { css } from 'styled-components';
import { COLORS } from '../assets/styles/variables';
import FontIcon from '../atoms/FontIcon';
import { mobile } from '../utils/media';

const AnchorLinkEl = styled(AnchorLink)`
  display: block;
  width: fit-content;
  position: absolute;
  height: fit-content;
  margin: 0 auto;
  left: 50%;
  bottom: 8%;
  transform: translateX(-50%);

  ${mobile(css`
    bottom: 10%;
  `)}

  @media (max-height: 400px) {
    display: none;
  }
`;

const ScrollBottomButton = ({ section, color, size }) => (
  <AnchorLinkEl offset="50" href={section}>
    <FontIcon color={color} icon={'scroll-down'} size={size} />
  </AnchorLinkEl>
);

ScrollBottomButton.propTypes = {
  section: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
};

ScrollBottomButton.defaultProps = {
  color: COLORS.txtLight,
  size: '50px',
};

export default ScrollBottomButton;
