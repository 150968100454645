import * as PropTypes from 'prop-types';
import React from 'react';
import Text from '../../atoms/Text';

const SectionHeaderSubText = ({ children, bold }) => (
  <Text lineHeight={1.8} block weight={bold && 'bold'} size={'20px'} style={{ marginTop: 20 }}>
    {children}
  </Text>
);

SectionHeaderSubText.propTypes = {
  children: PropTypes.any.isRequired,
  block: PropTypes.bool,
  bold: PropTypes.bool,
};

SectionHeaderSubText.defaultProps = {
  block: false,
};

export default SectionHeaderSubText;
