import React from 'react';
import * as PropTypes from 'prop-types';
import { COLORS } from '../../assets/styles/variables';
import Text from '../../atoms/Text';
import styled, { css } from 'styled-components';
import { mobile } from '../../utils/media';

const TextTitle = styled(Text)`
  font-size: 60px;
  line-height: 120px;
  ${mobile(css`
    font-size: 35px;
    line-height: 40px;
  `)}
`;

const CTABannerMainText = ({ children, ...props }) => (
  <TextTitle color={COLORS.txtLight} weight={'bold'} transform={'uppercase'} block {...props}>
    {children}
  </TextTitle>
);

CTABannerMainText.propTypes = {
  children: PropTypes.string.isRequired,
};

CTABannerMainText.defaultProps = {};

export default CTABannerMainText;
