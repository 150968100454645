import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import TAPLogo from '../assets/images/tap-logo-footer.svg';
import { BtnTypes, CenteredRowGrid } from '../assets/styles/Utils';
import { COLORS } from '../assets/styles/variables';
import Button from '../atoms/Button';
import FontIcon from '../atoms/FontIcon';
import Spacer from '../atoms/Spacer';
import { NAV_ITEMS } from '../config/constants';
import Nav from '../molecules/Nav';
import { mobile } from '../utils/media';

const HeaderContaner = styled(CenteredRowGrid)`
  background: ${COLORS.primary};
  color: ${COLORS.txtLight};
  height: 70px;
  padding: 0 3%;
  width: 100%;
  grid-template-columns: 2fr 4fr 2fr;
  justify-content: space-between;
  box-shadow: 0 1px 8px ${COLORS.shadowDark};
  z-index: 999;
  position: fixed;
  top: 0;

  @media only screen and (max-width: 1230px) {
    grid-template-columns: 105px 7fr 3fr;
  }

  ${mobile(css`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 70px;
  `)}
`;

const ActionsWrapper = styled.div`
  text-align: right;

  ${mobile(css`
    display: none;
  `)}
`;

const HeaderTapLogo = styled.img`
  width: 160px;
  height: auto;
`;

const BurgerIcon = styled(FontIcon)`
  display: none;
  ${mobile(css`
    font-size: 24px;
    display: block;
  `)}
`;

const CloseIcon = styled(FontIcon)`
  display: none;
  ${mobile(css`
    font-size: 24px;
    display: block;
  `)}
`;

const Header = ({ t, loginCb, signupCb, isMenuOpen, toggleMenu, ...rest }) => {
  return (
    <HeaderContaner>
      <Link to={NAV_ITEMS.home.linkTo}>
        <HeaderTapLogo alt={'TAP'} src={TAPLogo} />
      </Link>
      <Nav />
      <ActionsWrapper>
        <Button role={BtnTypes.outlined} onClick={loginCb}>
          {t('app:login')}
        </Button>
        <Spacer width={'20px'} />

        <Button noShadow role={BtnTypes.secondary} onClick={signupCb}>
          {t('app:signup')}
        </Button>
      </ActionsWrapper>
      {isMenuOpen ? (
        <CloseIcon icon="close" color={'#fff'} onClick={toggleMenu} />
      ) : (
        <BurgerIcon icon="hamburger" color={'#fff'} onClick={toggleMenu} />
      )}
    </HeaderContaner>
  );
};

Header.propTypes = {
  loginCb: PropTypes.func.isRequired,
  signupCb: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

Header.defaultProps = {};

export default withTranslation()(Header);
