import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../assets/styles/variables';
import * as cn from 'classnames';

const FontIconEl = styled.div`
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  margin: 0 10px;
  color: ${({ color }) => color || COLORS.txtDark};
  font-size: ${({ size }) => size || '16px'};
  margin-top: 2px;
  cursor: ${({ onClick }) => !!onClick && 'pointer'};
`;

const FontIcon = ({ icon, color, size, block, onClick, style, className }) => {
  const classes = cn(className, `icon-${icon}`);
  return <FontIconEl onClick={onClick} color={color} size={size} className={classes} block={block} style={style} />;
};

FontIcon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  block: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

FontIcon.defaultProps = {};

export default FontIcon;
