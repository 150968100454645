import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import React from 'react';
import { COLORS } from '../assets/styles/variables';
import styled, { css } from 'styled-components';
import FontIcon from '../atoms/FontIcon';
import { phone } from '../utils/media';

const ForwardLinkEl = styled.span`
  color: ${({ color }) => color || COLORS.txtDark};
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;

  a {
    border-bottom: ${({ underlined, color }) => underlined && `1px solid ${color || COLORS.txtDark}`};
  }

  ${phone(css`
    text-align: left;
    padding: 10px;
    font-size: 14px;
  `)}
`;

const ForwardLink = ({ className, underlined, color, linkTo, children, iconColor, icon }) => (
  <ForwardLinkEl className={className} color={color} underlined={underlined}>
    <Link to={linkTo}>{children}</Link>
    {icon && <FontIcon icon={icon} color={iconColor || COLORS.brand} />}
  </ForwardLinkEl>
);

ForwardLink.propTypes = {
  linkTo: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  underlined: PropTypes.bool,
};

ForwardLink.defaultProps = {};

export default ForwardLink;
