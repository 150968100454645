import React from 'react';
import styled, { css } from 'styled-components';
import footerLogo from '../../assets/images/tap-logo-footer.svg';
import { mobile } from '../../utils/media';

const FooterTapLogoEl = styled.img`
  width: 300px;
  ${mobile(css`
    width: 200px;
    padding: 20px;
  `)}
`;

const FooterTapLogo = () => {
  return <FooterTapLogoEl src={footerLogo} alt={'Topcon Agriculture Platform'} title={'Topcon Agriculture Platform'} />;
};

FooterTapLogo.propTypes = {};

export default FooterTapLogo;
