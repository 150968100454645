import * as PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { mobile } from '../../utils/media';
import { COLORS } from '../../assets/styles/variables';
import Text from '../../atoms/Text';

const SubTextContainer = styled.div`
  text-align: center;
  padding: 0 5%;
  margin: auto;
  max-width: 1000px;

  ${mobile(css`
    padding: 2% 0;
  `)}
`;

const SubText = styled(Text)`
  ${mobile(css`
    font-size: 18px;
    line-height: 1.5px;
    font-weight: 400;
    word-spacing: 4px;
    line-height: 2;
  `)}
`;

const CTABannerSubText = ({ children }) => (
  <SubTextContainer>
    <SubText lineHeight={'2'} color={COLORS.txtLight} block size={'22px'}>
      {children}
    </SubText>
  </SubTextContainer>
);

CTABannerSubText.propTypes = {
  children: PropTypes.any.isRequired,
  block: PropTypes.bool,
};

CTABannerSubText.defaultProps = {
  block: false,
};

export default CTABannerSubText;
